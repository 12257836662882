body {
  font-family:"Manjari";
  font-size: 16px;
}

a {
  color: #1dc0f7
}

h2 {
  color: #1dc0f7;
  margin-bottom: 5px;
}

.center {
  text-align: center;
  display: block;
}

.center > div{
  justify-content: space-between;
  display: inline-block;
}

#brand > a {
  font-family: 'Amatic SC';
  color: black;
  text-decoration: none;
  font-size: 30px;
}

#menu {
  font-family: 'Cinzel';
  color: black;
  text-decoration: none;
}

#editor {
  border: 1px solid gray;
  border-radius: 3px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.border {
  padding: 5px 15px 5px 15px;
  border-bottom: 1px solid #d3d3d3;
}

.nav-text, .nav-text > a {
  color: black !important;
  font-family: "Cinzel";
  font-weight: bold;
}

.menu__container .ant-menu-item {
  top: 0px !important;
}

.ant-menu-horizontal > .ant-menu-item-active {
  vertical-align: baseline !important;
  border-bottom: transparent !important;
}

.ant-menu-item-selected {
  color: white !important;
  border-bottom: transparent !important;
  background-color: white !important;
  vertical-align: baseline !important;
}

.menu__mobile-button {
  float: left;
  height: 32px;
  padding: 6px;
  background: white;
  margin: 5px 5px 5px 5px;
  display: none; /* use of important to overwrite ant-btn */
  border-color: grey;
}

.ant-drawer-body {
  background-color: white !important;
}

.menu_drawer .ant-drawer-body {
  padding: 0px !important;
}

.ant-drawer-wrapper-body {
  background: white;
}

.menu_drawer .ant-drawer-header {
  padding: 14px 24px !important;
}

.login-form-button {
  width: 100% !important;
}

.ant-form {
  max-width: 500px;
  margin: 20px auto -10px;
  display: block;
}

.blog-form {
  max-width: 850px;
  margin: 20px auto -10px;
  display: block;
}

.g-recaptcha {
  display: inline-block;
}

@media (max-width: 767px) {
  .menu__mobile-button {
    display: inline-block !important;
  }

  #brand {
    border-bottom: 1px solid #d3d3d3;
  }

  .ant-menu-root {
    border-right: 0px;
  }

  .nav-menu {
    display: none;
  }

  .menu__logo a {
    margin-left: -20px;
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid white !important;
  }
}

.responsive-img {
  width: 100%;
  height: auto;
}

.polaroid {
  padding: 10px 10px 10px 10px;
  background-color: white;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 40px 0 rgba(0, 0, 0, 0.19);
  margin: 5px 5px 5px 5px;
  max-width: 600px;
  display: inline-block;
}

.photo-desc {
  text-align: center;
  padding: 10px 10px;
}

@media only screen and (max-width: 1100px) {
  .polaroid {
      padding: 10px 10px 10px 10px;
      background-color: white;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 40px 0 rgba(0, 0, 0, 0.19);
      margin: 10px 5px 25px 5px;
      display: block;
      max-width: 100%;
  }
}

